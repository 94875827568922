// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EUbbLA4f{background-color:var(--color-body);color:var(--color-text);overflow-x:hidden}.JMAr9LlO,.EUbbLA4f{min-height:100vh}.JMAr9LlO{flex-grow:1;margin:0 auto;max-width:1440px;padding:0 16px;width:100%}.JMAr9LlO *{font-family:var(--landing-font)}@media(min-width:667.98px){.JMAr9LlO{padding:0 24px}}@media(min-width:947.98px){.JMAr9LlO{padding:0 32px}}._7rb93C52{opacity:0;pointer-events:none;position:absolute;-webkit-user-select:none;-moz-user-select:none;user-select:none;visibility:hidden}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"wrapper": "EUbbLA4f",
	"grid": "JMAr9LlO",
	"asset": "_7rb93C52"
};
module.exports = ___CSS_LOADER_EXPORT___;
